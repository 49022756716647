import { t } from 'i18next'
import { baseWorksheetSchema } from './BaseWorksheetSchema'
import yupExtInt from './common/SchemaTypes'
import * as yup from './common/yup-extended'
import { Constants, EWorksheetStatus, EWorksheetStatusList } from './constants'
import {
  IFinanceBaseWorksheet,
  IFinanceBaseWorksheetDecisionDTOSchema,
  IFinanceBaseWorksheetRevision,
} from './IFinanceBaseWorksheetSchema'
import { MerchantPayment } from './MerchantPayment'

const personalLoanWorksheetSchema = yup.default
  .object({
    amountRequested: yupExtInt.double.positive(),
    includeInsurance: yup.default.boolean().default(false),
    addFastPayments: yup.default.boolean().default(false),
    fundConfirmationNote: yup.default.string().default('').nullable(),
    merchantPayments: yup.default.mixed<MerchantPayment[]>().default([]),
    paymentPlanId: yup.default.string().required(),
  })
  .concat(baseWorksheetSchema)

export const PersonalLoanWorksheetSchema = yup.default.object({ ...personalLoanWorksheetSchema.fields })

export type PersonalLoanWorksheet = IFinanceBaseWorksheet

export type PersonalWorksheetRevision = IFinanceBaseWorksheetRevision

const personalWorksheetDecisionDTOSchema = IFinanceBaseWorksheetDecisionDTOSchema
export type PersonalWorksheetDecisionDTO = yup.default.InferType<typeof personalWorksheetDecisionDTOSchema>

const editPersonalLoanWorksheetDtoSchema = yup.default.object({
  status: yup.default.mixed<EWorksheetStatus>().oneOf(EWorksheetStatusList).required().default(EWorksheetStatus.Draft),
  amountRequested: yupExtInt.double.positive(),
  deliveryOn: yup.default.string().default('').nullable(false).required('common.errors.required'),
  firstPaymentOn: yup.default
    .date()
    .default(new Date())
    .nullable()
    .required('common.errors.required')
    .typeError('common.errors.required'),
  includeInsurance: yup.default.boolean().default(false),
  addFastPayments: yup.default.boolean().default(false),
  paymentFrequency: yup.default.string().default('').nullable(false).required(),
  term: yupExtInt.integer
    .min(6)
    .nullable(false)
    .required()
    .when('amountRequested', {
      is: (value: number) => value && value < Constants.LargeLoanAmount,
      then: (schema) =>
        schema.max(72, () =>
          t('common.errors.term84', { longTerm: Constants.LongTerm, minAmountForLongTerm: Constants.LargeLoanAmount }),
        ),
      otherwise: (schema) => schema.max(Constants.LongTerm),
    }),
  fundConfirmationNote: yup.default.string().default('').nullable(),
  merchantPayments: yup.default.mixed<MerchantPayment[]>().default([]),
  paymentPlanId: yup.default.string().required(),
  creditApplicationId: yup.default.string(),
  versionTag: yup.default.string(),
})
export const EditPersonalLoanWorksheetDtoSchema = yup.default.object({ ...editPersonalLoanWorksheetDtoSchema.fields })
export type EditPersonalLoanWorksheetDto = yup.default.InferType<typeof EditPersonalLoanWorksheetDtoSchema>
